import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {api, API} from "../api/api";
import bannersSlice from "../slices/bannersSlice";
import dropsSlice from "../slices/dropsSlice";
import productsSlice from "../slices/productsSlice";
import walletsSlice from "../slices/walletsSlice";
import somethingSlice from "../slices/tmpPatternSlice";
import authSlice from "../slices/authSlice";
import wtbsSlice from "../slices/wtbsSlice";
import notificationsSlice from "../slices/notificationsSlice";
import settingsSlice from "../slices/settingsSlice";
import promotionsSlice from "../slices/promotionsSlice";

export const rootReducer = combineReducers({
    banners: bannersSlice,
    drops: dropsSlice,
    products: productsSlice,
    wallets: walletsSlice,
    wtbs: wtbsSlice,
    notifications: notificationsSlice,
    somethings: somethingSlice,
    auth: authSlice,
    settings: settingsSlice,
    promotions: promotionsSlice,
    [api.reducerPath]: api.reducer,
});

export const middleware = [api.middleware];

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(...middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = Omit<ReturnType<typeof store.getState>, typeof API>;
