import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {routes} from "../../../app/routes";
import TextInput from "../../../components/form/TextInput";
import {ProgressSpinner} from "primereact/progressspinner";
import {
    useCreatePromotionBrandMutation,
    useFindPromotionBrandByIdQuery,
    useUpdatePromotionBrandMutation
} from "../../../api/grailzApi";
import {
    initialPromotionBrandState,
    selectSettingState,
    setPromotionBrandProperty,
    setSettingData
} from "../../../slices/settingsSlice";
import UploadImageInput from "../../../components/form/UploadImageInput";

const UpdatePromotionBrand: FC<{}> = ({}) => {
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {isLoading: isPrepareLoading} = useFindPromotionBrandByIdQuery(
        {id: id},
        {skip: id === ""}
    );
    const [
        updateBrand,
        {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess},
    ] = useUpdatePromotionBrandMutation();
    const [
        createBrand,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreatePromotionBrandMutation();

    const {promotionBrand} = useAppSelector(selectSettingState);
    const {name, imageUrl, priority} = promotionBrand;

    const isSuccess = isUpdateSuccess || isCreateSuccess;

    useEffect(() => {
        dispatch(
            setSettingData({
                property: "promotionBrand",
                value: initialPromotionBrandState,
            })
        );

        if (isSuccess) {
            navigate(`/${routes.SETTINGS}/${routes.PROMOTION_BRANDS}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        !!id
            ? updateBrand({id: id, brandDto: promotionBrand})
            : createBrand({brandDto: promotionBrand});
    };

    return (
        <>
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {!id
                        ? `Dodaj brand`
                        : `Zaaktualizuj brand ${name}`}
                </h3>
                <TextInput
                    id="name"
                    name="name"
                    value={name}
                    label="Nazwa"
                    setProperty={setPromotionBrandProperty}
                />
                <TextInput
                    id="priority"
                    name="priority"
                    value={priority}
                    label="Priorytet (czym wyższy priorytet, tym promocja znajduje się wyżej w aplikacji)"
                    setProperty={setPromotionBrandProperty}
                />
                <UploadImageInput
                    id="imageUrl"
                    name="imageUrl"
                    value={imageUrl}
                    label="Zdjęcie brandu"
                    setProperty={setPromotionBrandProperty}
                />
                {isCreateLoading || isUpdateLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ProgressSpinner/>
                    </div>
                ) : (
                    <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit}/>
                )}
            </div>
        </>
    );
};

export default UpdatePromotionBrand;
