import React, {FC, ReactNode} from "react";
import {DataTable} from "primereact/datatable";
import {ProgressSpinner} from "primereact/progressspinner";
import {Column, ColumnBodyType} from "primereact/column";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";

const TableWrapper: FC<{
    header?: string;
    sortField?: string;
    isLoading: boolean;
    data: any[] | undefined;
    columns: {
        field: string;
        header: string;
        body?: ColumnBodyType;
        style?: any;
        sortable?: boolean;
    }[];
    paginator?: boolean;
    size?: number;
    page?: number;
    first?: number;
    totalElements?: number;
    setData?: any;
    addButton: ReactNode;
    globalFilter?: string;
    stateKey?: string;
}> = ({
          header,
          sortField,
          isLoading,
          data,
          columns,
          paginator = false,
          size = 15,
          first = 0,
          totalElements = 1,
          setData,
          addButton,
          globalFilter,
          stateKey,
      }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const renderHeader = (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <h4 style={{margin: 0}}>{header}</h4>
            {addButton}
        </div>
    );

    if (isLoading) {
        return <ProgressSpinner/>;
    }

    return (
        <>
            <DataTable
                header={renderHeader}
                sortField={sortField}
                value={data !== undefined ? data : []}
                paginator={paginator}
                lazy={paginator}
                rows={size}
                first={first}
                totalRecords={totalElements}
                sortMode="multiple"
                onPage={(event) => {
                    dispatch(setData({property: "first", value: event.first}));
                    dispatch(setData({property: "page", value: event.page}));
                }}
                globalFilter={globalFilter}
                stateKey={stateKey}
            >
                {columns.map((col) => {
                    return (
                        <Column
                            key={col.field}
                            field={col.field}
                            header={col.header}
                            body={col.body}
                            style={col?.style}
                            sortable={col?.sortable}
                        />
                    );
                })}
            </DataTable>
        </>
    );
};

export default TableWrapper;
