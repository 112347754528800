import React, {FC} from "react";
import TableWrapper from "../../../components/table/TableWrapper";
import {routes} from "../../../app/routes";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import TextInput from "../../../components/form/TextInput";
import {useAppSelector} from "../../../app/hooks";
import brandActionTemplate from "./Actions";
import {PrimeIcons} from "primereact/api";
import {
    selectSettingState,
    setSettingData,
} from "../../../slices/settingsSlice";
import {BrandDto, useFindPromotionBrandsQuery} from "../../../api/grailzApi";
import {Image} from "primereact/image";

const PromotionBrands: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {promotionBrandFilter} = useAppSelector(selectSettingState);
    const {isLoading, data} = useFindPromotionBrandsQuery({
        page: 0,
        size: 10000,
    });

    const columns = [
        {field: "name", header: "Nazwa", sortable: true},
        {field: "priority", header: "Priorytet", sortable: true},
        {
            field: "imageUrl",
            header: "Ikona",
            body: (row: BrandDto) => <Image src={row.imageUrl} width="100"/>,
        },
        {
            field: "",
            header: "Akcje",
            body: brandActionTemplate,
        },
    ];

    return (
        <>
            <TextInput
                id="promotionBrandFilter"
                name="promotionBrandFilter"
                label=""
                value={promotionBrandFilter}
                setProperty={setSettingData}
            />
            <TableWrapper
                header="Brandy"
                isLoading={isLoading}
                data={data?.content}
                columns={columns}
                globalFilter={promotionBrandFilter}
                addButton={
                    <Button
                        onClick={() => navigate(routes.ADD)}
                        label="Dodaj"
                        icon={PrimeIcons.PLUS}
                    />
                }
                stateKey="brands-list"
            />
        </>
    );
};

export default PromotionBrands;
