import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    useCreatePromotionMutation,
    useEditPromotionMutation, useFindPromotionBrandsQuery,
    useFindPromotionByIdQuery, useFindPromotionCategoriesQuery
} from "../../api/grailzApi";
import {routes} from "../../app/routes";
import TextInput from "../../components/form/TextInput";
import UploadImageInput from "../../components/form/UploadImageInput";
import {
    initialPromotionState,
    selectPromotionState,
    setPromotionData,
    setPromotionProperty
} from "../../slices/promotionsSlice";
import SelectInput from "../../components/form/SelectInput";

const UpdatePromotion: FC<{}> = ({}) => {
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {isLoading: isPrepareLoading} = useFindPromotionByIdQuery(
        {id: id},
        {skip: id === ""}
    );
    const [
        updatePromotion,
        {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess},
    ] = useEditPromotionMutation();
    const [
        createPromotion,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreatePromotionMutation();

    const {promotion} = useAppSelector(selectPromotionState);
    const {
        name,
        imageUrl,
        priority,
        genderType,
        enabled,
        referralLink,
        price,
        categoryId,
        brandId,
        shopCategorySlug,
        shopProductId
    } = promotion;

    const isSuccess = isUpdateSuccess || isCreateSuccess;

    useEffect(() => {
        dispatch(setPromotionData({property: "promotion", value: initialPromotionState}));

        if (isSuccess) {
            navigate(`/${routes.PROMOTIONS}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        !!id
            ? updatePromotion({id: id, promotionRequest: promotion})
            : createPromotion({promotionRequest: promotion});
    };

    const genderTypes = [
        {value: 'MALE', label: 'Męskie'},
        {value: 'FEMALE', label: 'Damskie'},
        {value: 'UNISEX', label: 'Unisex'}
    ];

    const enabledOptions = [
        {value: true, label: 'Tak'},
        {value: false, label: 'Nie'}
    ];

    const {data: categories} = useFindPromotionCategoriesQuery({page: 0, size: 10000});
    const categoryOptions = categories?.content?.map(item => {
        return {value: item.id, label: item.name}
    }) ?? [];

    const {data: brands} = useFindPromotionBrandsQuery({page: 0, size: 10000});
    const brandOptions = brands?.content?.map(item => {
        return {value: item.id, label: item.name}
    }) ?? [];

    return (
        <>
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {!id
                        ? `Dodaj promocję`
                        : `Aktualizuj promocję ${name}`}
                </h3>
                <TextInput
                    id="name"
                    name="name"
                    value={name}
                    label="Nazwa"
                    setProperty={setPromotionProperty}
                />
                <TextInput
                    id="priority"
                    name="priority"
                    value={priority}
                    label="Priorytet (czym wyższy priorytet, tym promocja znajduje się wyżej w aplikacji)"
                    setProperty={setPromotionProperty}
                />
                <UploadImageInput
                    id="imageUrl"
                    name="imageUrl"
                    value={imageUrl}
                    label="Zdjęcie"
                    setProperty={setPromotionProperty}
                />
                <SelectInput
                    id="genderType"
                    options={genderTypes}
                    name="genderType"
                    value={genderType}
                    label="Płeć"
                    setProperty={setPromotionProperty}
                />
                <SelectInput
                    id="categoryId"
                    options={categoryOptions}
                    name="categoryId"
                    value={categoryId}
                    label="Kategoria"
                    setProperty={setPromotionProperty}
                />
                <SelectInput
                    id="brandId"
                    options={brandOptions}
                    name="brandId"
                    value={brandId}
                    label="Brand"
                    setProperty={setPromotionProperty}
                />
                <SelectInput
                    id="enabled"
                    options={enabledOptions}
                    name="enabled"
                    value={enabled}
                    label="Widoczne w aplikacji"
                    setProperty={setPromotionProperty}
                />
                <TextInput
                    id="price"
                    name="price"
                    value={price}
                    label="Cena"
                    setProperty={setPromotionProperty}
                />
                <TextInput
                    id="referralLink"
                    name="referralLink"
                    value={referralLink}
                    label="Link referencyjny"
                    setProperty={setPromotionProperty}
                />
                <TextInput
                    id="shopCategorySlug"
                    name="shopCategorySlug"
                    value={shopCategorySlug}
                    label="Slug do kategorii w sklepie, np. buty-nike"
                    setProperty={setPromotionProperty}
                />
                <TextInput
                    id="shopProductId"
                    name="shopProductId"
                    value={shopProductId}
                    label="ID do produktu w sklepie np. 283740"
                    setProperty={setPromotionProperty}
                />
                <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit}/>
            </div>
        </>
    );
};

export default UpdatePromotion;
