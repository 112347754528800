export const cacheTags = {
    BANNERS: 'Banners',
    DROPS: 'Drops',
    PRODUCTS: 'Products',
    WALLETS: 'Wallets',
    WTBS: 'Wtbs',
    PROMOTIONS: 'Promotions',
    PROMOTION_GROUP_CATEGORIES: 'PromotionGroupCategories',
    PROMOTION_CATEGORIES: 'PromotionCategories',
    PROMOTION_BRANDS: 'PromotionBrands',
}