import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {routes} from "../../../app/routes";
import TextInput from "../../../components/form/TextInput";
import {ProgressSpinner} from "primereact/progressspinner";
import {
    initialPromotionGroupCategoryState,
    selectSettingState,
    setPromotionGroupCategoryProperty,
    setSettingData,
} from "../../../slices/settingsSlice";
import {
    useCreatePromotionGroupCategoryMutation,
    useFindPromotionGroupCategoryByIdQuery,
    useUpdatePromotionGroupCategoryMutation
} from "../../../api/grailzApi";

const UpdatePromotionGroupCategory: FC<{}> = ({}) => {
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {isLoading: isPrepareLoading} = useFindPromotionGroupCategoryByIdQuery(
        {id: id},
        {skip: id === ""}
    );
    const [
        updateGroupCategory,
        {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess},
    ] = useUpdatePromotionGroupCategoryMutation();
    const [
        createGroupCategory,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreatePromotionGroupCategoryMutation();

    const {promotionGroupCategory} = useAppSelector(selectSettingState);
    const {name} = promotionGroupCategory;

    const isSuccess = isUpdateSuccess || isCreateSuccess;

    useEffect(() => {
        dispatch(
            setSettingData({
                property: "promotionGroupCategory",
                value: initialPromotionGroupCategoryState,
            })
        );

        if (isSuccess) {
            navigate(`/${routes.SETTINGS}/${routes.PROMOTION_GROUP_CATEGORIES}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        !!id
            ? updateGroupCategory({id: id, groupCategoryDto: promotionGroupCategory})
            : createGroupCategory({groupCategoryDto: promotionGroupCategory});
    };

    return (
        <>
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {!id
                        ? `Dodaj grupę kategorii`
                        : `Zaaktualizuj grupę kategorii ${name}`}
                </h3>
                <TextInput
                    id="name"
                    name="name"
                    value={name}
                    label="Nazwa"
                    setProperty={setPromotionGroupCategoryProperty}
                />
                {isCreateLoading || isUpdateLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ProgressSpinner/>
                    </div>
                ) : (
                    <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit}/>
                )}
            </div>
        </>
    );
};

export default UpdatePromotionGroupCategory;
