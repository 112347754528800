import React, {FC} from "react";
import {useKeycloak} from "@react-keycloak-fork/web";
import {NavLink} from "react-router-dom";
import {Menubar} from "primereact/menubar";
import {Button} from "primereact/button";
import {MenuItem, MenuItemOptions} from "primereact/menuitem";
import {Image} from "primereact/image";
import image from "../logo.png";

const Template: FC<{ item: MenuItem; options: MenuItemOptions }> = ({
                                                                        item,
                                                                        options,
                                                                    }) => {
    return (
        <NavLink
            to={`${item.url}`}
            className={({
                            isActive,
                            isPending
                        }) => isPending ? options.className : isActive ? options.className + " nav-link-active" : options.className}
            onClick={options.onClick}
        >
            <i className={options.iconClassName}/>{" "}
            <span className={options.labelClassName}>{item.label}</span>
        </NavLink>
    );
};

export const template = (item: MenuItem, options: MenuItemOptions) => (
    <Template item={item} options={options}/>
);

const items = [
    {
        label: "Banery afiliacyjne",
        icon: "pi pi-fw pi-file",
        url: "/banners",
        template,
    },
    {
        label: "Promocje",
        icon: "pi pi-fw pi-images",
        url: "/promotions",
        template,
    },
    {
        label: "Dropy",
        icon: "pi pi-fw pi-pencil",
        url: "/drops",
        template,
    },
    {
        label: "Skarbce użytkowników",
        icon: "pi pi-fw pi-user",
        url: "/wallets",
        template,
    },
    {
        label: "Produkty",
        icon: "pi pi-fw pi-calendar",
        url: "/products",
        template,
    },
    {
        label: "Ustawienia",
        icon: "pi pi-fw pi-database",
        url: "/settings",
        template,
    }
];

const Nav = () => {
    const {keycloak} = useKeycloak();

    return (
        <>
            <Menubar
                start={
                    <div style={{marginRight: 10, fontWeight: "bold", display: "flex"}}>
                        <Image src={image} width="30"/>
                        <div style={{margin: "auto", paddingLeft: 5}}>
                            Grailz management
                        </div>
                    </div>
                }
                model={items}
                end={
                    <Button
                        label={
                            !!keycloak.authenticated
                                ? "Logout " + keycloak.tokenParsed?.preferred_username
                                : "Login"
                        }
                        icon="pi pi-power-off"
                        onClick={() =>
                            !!keycloak.authenticated ? keycloak.logout() : keycloak.login()
                        }
                    />
                }
            />
        </>
    );
};

export default Nav;
