import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BrandDto, CategoryDto, GroupCategoryDto} from "../api/grailzApi";

type TSettingState = {
    promotionCategory: CategoryDto;
    promotionCategoryFilter: string;
    promotionGroupCategory: GroupCategoryDto;
    promotionGroupCategoryFilter: string;
    promotionBrand: BrandDto;
    promotionBrandFilter: string;
};

export const initialPromotionCategoryState: CategoryDto = {
    id: "",
    groupCategoryId: "",
    name: "",
};

export const initialPromotionGroupCategoryState: GroupCategoryDto = {
    id: "",
    name: "",
};

export const initialPromotionBrandState: BrandDto = {
    id: "",
    name: "",
    imageUrl: "",
    priority: 0,
};

const initialState: TSettingState = {
    promotionCategory: initialPromotionCategoryState,
    promotionGroupCategory: initialPromotionGroupCategoryState,
    promotionBrand: initialPromotionBrandState,
    promotionCategoryFilter: "",
    promotionGroupCategoryFilter: "",
    promotionBrandFilter: "",
};

export const settingsSlice = createSlice({
    name: "SETTING",
    initialState,
    reducers: {
        setSettingData: <Property extends keyof TSettingState>(
            state: TSettingState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: TSettingState[Property] }>
        ) => {
            const {property, value} = payload;
            state[property] = value;
        },
        setPromotionCategoryProperty: <Property extends keyof CategoryDto>(
            state: TSettingState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: CategoryDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.promotionCategory[property] = value;
        },
        setPromotionGroupCategoryProperty: <Property extends keyof GroupCategoryDto>(
            state: TSettingState,
            {
                payload,
            }: PayloadAction<{
                property: Property;
                value: GroupCategoryDto[Property];
            }>
        ) => {
            const {property, value} = payload;
            state.promotionGroupCategory[property] = value;
        },
        setPromotionBrandProperty: <Property extends keyof BrandDto>(
            state: TSettingState,
            {
                payload,
            }: PayloadAction<{
                property: Property;
                value: BrandDto[Property];
            }>
        ) => {
            const {property, value} = payload;
            state.promotionBrand[property] = value;
        },
    },
});

export const {
    setSettingData,
    setPromotionCategoryProperty,
    setPromotionGroupCategoryProperty,
    setPromotionBrandProperty
} =
    settingsSlice.actions;

export const selectSettingState = (state: any) => state.settings;
export const selectPromotionCategoryState = (state: any) => state.settings.promotionCategory;
export const selectPromotionGroupCategoryState = (state: any) => state.settings.promotionGroupCategory;
export const selectPromotionBrandState = (state: any) => state.settings.promotionBrand;

export default settingsSlice.reducer;
