import React, {FC} from "react";
import TableWrapper from "../../../components/table/TableWrapper";
import {routes} from "../../../app/routes";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import TextInput from "../../../components/form/TextInput";
import {useAppSelector} from "../../../app/hooks";
import promotionGroupCategoryActionTemplate from "./Actions";
import {PrimeIcons} from "primereact/api";
import {
    selectSettingState,
    setSettingData,
} from "../../../slices/settingsSlice";
import {useFindPromotionGroupCategoriesQuery} from "../../../api/grailzApi";

const PromotionGroupCategories: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {promotionGroupCategoryFilter} = useAppSelector(selectSettingState);
    const {isLoading, data} = useFindPromotionGroupCategoriesQuery({
        page: 0,
        size: 10000,
    });

    const columns = [
        {field: "name", header: "Nazwa", sortable: true},
        {
            field: "",
            header: "Akcje",
            body: promotionGroupCategoryActionTemplate,
        },
    ];

    return (
        <>
            <TextInput
                id="promotionGroupCategoryFilter"
                name="promotionGroupCategoryFilter"
                label=""
                value={promotionGroupCategoryFilter}
                setProperty={setSettingData}
            />
            <TableWrapper
                header="Grupy kategorii"
                isLoading={isLoading}
                data={data?.content}
                columns={columns}
                globalFilter={promotionGroupCategoryFilter}
                addButton={
                    <Button
                        onClick={() => navigate(routes.ADD)}
                        label="Dodaj"
                        icon={PrimeIcons.PLUS}
                    />
                }
                stateKey="promotion-group-categories-list"
            />
        </>
    );
};

export default PromotionGroupCategories;
