import React, {FC} from "react";
import TableWrapper from "../../components/table/TableWrapper";
import promotionActionTemplate from "./Actions";
import {
    PromotionResponse,
    useFindPromotionCategoriesQuery,
    useGetAllPromotionsQuery
} from "../../api/grailzApi";
import {routes} from "../../app/routes";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {Image} from "primereact/image";
import TextInput from "../../components/form/TextInput";
import {selectPromotionState, setPromotionData} from "../../slices/promotionsSlice";
import {useAppSelector} from "../../app/hooks";

const genderTypes = {
    'MALE': 'Męskie',
    'FEMALE': 'Damskie',
    'UNISEX': 'Unisex',
    '': 'Nieznana'
};

const Promotions: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {isLoading, data = []} = useGetAllPromotionsQuery();

    const {promotionFilter} = useAppSelector(selectPromotionState);

    const {data: categories} = useFindPromotionCategoriesQuery({
        page: 0,
        size: 10000,
    });

    const columns = [
        {field: "name", header: "Nazwa", sortable: true},
        {field: "priority", header: "Priorytet", sortable: true},
        {
            field: "genderType",
            header: "Płeć",
            // @ts-ignore
            body: (row: PromotionResponse) => genderTypes[row?.genderType ?? ''],
            sortable: true
        },
        {
            field: "imageUrl",
            header: "Podgląd",
            body: (row: PromotionResponse) => <Image src={row.imageUrl} width="100"/>,
            sortable: true
        },
        {
            field: "categoryId",
            header: "Kategoria",
            sortable: true,
            body: (row: PromotionResponse) =>
                categories?.content?.find(
                    (item) => item.id === row.categoryId
                )?.name,
        },
        {
            field: "enabled",
            header: "Włączone w aplikacji",
            body: (row: PromotionResponse) => row.enabled ? "Tak" : "Nie",
            sortable: true
        },
        {
            field: "",
            header: "",
            body: promotionActionTemplate,
        },
    ];

    const addButton = (
        <>
            <Button onClick={() => navigate(routes.ADD)} label="Dodaj"/>
        </>
    );

    return (
        <>
            <TextInput
                id="filter-promotions"
                name="promotionFilter"
                label=""
                value={promotionFilter}
                setProperty={setPromotionData}
            />
            <TableWrapper
                header="Promocje"
                isLoading={isLoading}
                data={data}
                columns={columns}
                addButton={addButton}
                globalFilter={promotionFilter}
            />
        </>
    );
};

export default Promotions;
