import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {routes} from "../../../app/routes";
import TextInput from "../../../components/form/TextInput";
import {ProgressSpinner} from "primereact/progressspinner";
import SelectInput from "../../../components/form/SelectInput";
import {
    initialPromotionCategoryState,
    selectSettingState,
    setPromotionCategoryProperty,
    setSettingData
} from "../../../slices/settingsSlice";
import {
    useCreatePromotionCategoryMutation,
    useFindPromotionCategoryByIdQuery,
    useFindPromotionGroupCategoriesQuery,
    useUpdatePromotionCategoryMutation
} from "../../../api/grailzApi";

const UpdatePromotionCategory: FC<{}> = ({}) => {
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {data: groupCategories} = useFindPromotionGroupCategoriesQuery({
        page: 0,
        size: 10000,
    });
    const {isLoading: isPrepareLoading} = useFindPromotionCategoryByIdQuery(
        {id: id},
        {skip: id === ""}
    );
    const [
        updateCategory,
        {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess},
    ] = useUpdatePromotionCategoryMutation();
    const [
        createCategory,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreatePromotionCategoryMutation();

    const {promotionCategory} = useAppSelector(selectSettingState);
    const {name, groupCategoryId} = promotionCategory;

    const isSuccess = isUpdateSuccess || isCreateSuccess;

    useEffect(() => {
        dispatch(
            setSettingData({property: "promotionCategory", value: initialPromotionCategoryState})
        );

        if (isSuccess) {
            navigate(`/${routes.SETTINGS}/${routes.PROMOTION_CATEGORIES}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        !!id
            ? updateCategory({id: id, categoryDto: promotionCategory})
            : createCategory({categoryDto: promotionCategory});
    };

    return (
        <>
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {!id ? `Dodaj kategorię` : `Zaaktualizuj kategorię ${name}`}
                </h3>
                <TextInput
                    id="name"
                    name="name"
                    value={name}
                    label="Nazwa"
                    setProperty={setPromotionCategoryProperty}
                />
                <SelectInput
                    id="groupCategoryId"
                    options={
                        [...(groupCategories?.content?.map((item) => {
                            return {value: item.id, label: item.name};
                        }) || []), {value: null, label: 'Brak'}]
                    }
                    name="groupCategoryId"
                    value={groupCategoryId}
                    label="Grupa kategorii"
                    setProperty={setPromotionCategoryProperty}
                />
                {isCreateLoading || isUpdateLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ProgressSpinner/>
                    </div>
                ) : (
                    <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit}/>
                )}
            </div>
        </>
    );
};

export default UpdatePromotionCategory;
