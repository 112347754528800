import React, {FC} from "react";
import TableWrapper from "../../../components/table/TableWrapper";
import {routes} from "../../../app/routes";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import TextInput from "../../../components/form/TextInput";
import {useAppSelector} from "../../../app/hooks";
import categoryActionTemplate from "./Actions";
import {PrimeIcons} from "primereact/api";
import {selectSettingState, setSettingData} from "../../../slices/settingsSlice";
import {
    CategoryDto,
    useFindPromotionCategoriesQuery,
    useFindPromotionGroupCategoriesQuery
} from "../../../api/grailzApi";

const PromotionCategories: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {promotionCategoryFilter} = useAppSelector(selectSettingState);
    const {data: groupCategories} = useFindPromotionGroupCategoriesQuery({
        page: 0,
        size: 10000,
    });
    const {isLoading, data} = useFindPromotionCategoriesQuery({
        page: 0,
        size: 10000,
    });

    const columns = [
        {field: "name", header: "Nazwa", sortable: true},
        {
            field: "groupCategoryId",
            header: "Grupa kategorii",
            sortable: true,
            body: (row: CategoryDto) =>
                groupCategories?.content?.find(
                    (item) => item.id === row.groupCategoryId
                )?.name,
        },
        {
            field: "",
            header: "Akcje",
            body: categoryActionTemplate,
        },
    ];

    return (
        <>
            <TextInput
                id="promotionCategoryFilter"
                name="promotionCategoryFilter"
                label=""
                value={promotionCategoryFilter}
                setProperty={setSettingData}
            />
            <TableWrapper
                header="Kategorie"
                isLoading={isLoading}
                data={data?.content}
                columns={columns}
                globalFilter={promotionCategoryFilter}
                addButton={
                    <Button
                        onClick={() => navigate(routes.ADD)}
                        label="Dodaj"
                        icon={PrimeIcons.PLUS}
                    />
                }
                stateKey="promotion-categories-list"
            />
        </>
    );
};

export default PromotionCategories;
