export const routes = {
    HOME: "",
    BANNERS: "banners",
    DROPS: "drops",
    WALLETS: "wallets",
    PRODUCTS: "products",
    WTBS: "wtbs",
    SETTINGS: "settings",
    PROMOTIONS: "promotions",
    PROMOTION_GROUP_CATEGORIES: "group-categories",
    PROMOTION_CATEGORIES: "categories",
    PROMOTION_BRANDS: "brands",
    NOTIFICATIONS: "notifications",
    ADD: "add",
    EDIT: "edit",
};
