import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PromotionRequest} from "../api/grailzApi";

type TPromotionState = {
    promotion: PromotionRequest;
    promotionFilter: string;
};

export const initialPromotionState: PromotionRequest = {
    name: "",
    imageUrl: "",
    priority: 0,
}

const initialState: TPromotionState = {
    promotion: initialPromotionState,
    promotionFilter: ''
};

export const promotionsSlice = createSlice({
    name: "PROMOTION",
    initialState,
    reducers: {
        setPromotionData: <Property extends keyof TPromotionState>(
            state: TPromotionState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: TPromotionState[Property] }>
        ) => {
            const {property, value} = payload;
            state[property] = value;
        },
        setPromotionProperty: <Property extends keyof PromotionRequest>(
            state: TPromotionState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: PromotionRequest[Property] }>
        ) => {
            const {property, value} = payload;
            state.promotion[property] = value;
        }
    },
});

export const {setPromotionData, setPromotionProperty} = promotionsSlice.actions;

export const selectPromotionState = (state: any) => state.promotions;

export default promotionsSlice.reducer;
